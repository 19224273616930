import { SITES, getDomain } from "../../sites";
import { Language } from "../i18n/settings";

export type UrlTranslation = {
    original: string;
    translations: Record<Language, string>;
};

export const Urls = {
    home: {
        index: "/",
    },
    search: {
        index: {
            original: "s/",
            translations: {
                de: "s/",
                fr: "s/",
                it: "s/",
                pt: "s/",
                ru: "s/",
                tr: "s/",
                cn: "s/",
                es: "s/",
            },
        } as UrlTranslation,
    },
    room: {
        index: {
            original: "rooms/",
            translations: {
                de: "zimmer/",
                fr: "chambres/",
                it: "camere/",
                pt: "quartos/",
                ru: "nomera/",
                tr: "odalar/",
                cn: "客房/",
                es: "habitaciones/",
            },
        } as UrlTranslation,
    },
    city: {
        index: {
            original: "{city}/hotels/",
            translations: {
                pt: "{city}/hoteis/",
                ru: "{city}/gostinitsy/",
                tr: "{city}/oteller/",
                cn: "{city}/酒店/",
                es: "{city}/hoteles/",
                de: "{city}/langzeitmiete/",
            },
        } as UrlTranslation,
    },
    userProfile: {
        index: {
            original: "user-profile/",
            translations: {
                de: "benutzerprofil/",
                fr: "profil-utilisateur/",
                it: "profilo-utente/",
                ru: "профиль-пользователя/",
                tr: "kullanici-profil/",
                cn: "用户资料/",
                es: "perfil-usuario/",
            },
        } as UrlTranslation,
    },
    security: {
        index: {
            original: "security/",
            translations: {
                de: "sicherheit/",
                fr: "sécurité/",
                it: "sicurezza/",
                ru: "безопасность/",
                tr: "güvenlik/",
                cn: "安全/",
                es: "seguridad/",
            },
        } as UrlTranslation,
    },
    payment: {
        index: {
            original: "payment/",
            translations: {
                de: "zahlung/",
                fr: "payer/",
                it: "paga/",
                ru: "оплата/",
                tr: "ödemek/",
                cn: "付款/",
                es: "pagar/",
            },
        } as UrlTranslation,
    },
    billing: {
        index: {
            original: "billing/",
            translations: {
                de: "abrechnung/",
                fr: "facturation/",
                it: "fatturazione/",
                ru: "выставление-счета/",
                tr: "fatura/",
                cn: "结算/",
                es: "facturación/",
            },
        } as UrlTranslation,
    },
    messages: {
        index: {
            original: "messages/",
            translations: {
                de: "nachrichten/",
                fr: "messages/",
                it: "messaggi/",
                ru: "сообщения/",
                tr: "mesajlar/",
                cn: "消息/",
                es: "mensajes/",
            },
        } as UrlTranslation,
    },
    invoices: {
        index: {
            original: "invoices/",
            translations: {
                de: "rechnungen/",
                fr: "factures/",
                it: "fatture/",
                ru: "счета/",
                tr: "faturalar/",
                cn: "发票/",
                es: "facturas/",
            },
        } as UrlTranslation,
    },
    reservations: {
        index: {
            original: "reservations/",
            translations: {
                de: "reservierungen/",
                fr: "réservations/",
                it: "prenotazioni/",
                ru: "бронирования/",
                tr: "rezervasyonlar/",
                cn: "预订/",
                es: "reservas/",
            },
        } as UrlTranslation,
    },
    blog: {
        index: {
            original: "blog/",
            translations: {
                cn: "博客/",
            },
        } as UrlTranslation,
        post: {
            original: "blog/{post}/",
            translations: {
                cn: "博客/{post}/",
            },
        } as UrlTranslation,
        tag: {
            original: "blog/tag/{tag}/",
            translations: {
                cn: "博客/标签/{tag}/",
                tr: "blog/etiket/{tag}/",
            },
        } as UrlTranslation,
    },
    press: {
        index: {
            original: "press/",
            translations: {
                de: "presse/",
                fr: "presse/",
                it: "stampa/",
                pt: "imprensa/",
                ru: "pressa/",
                tr: "basin/",
                cn: "新闻/",
                es: "prensa/",
            },
        } as UrlTranslation,
        post: {
            original: "press/{post}/",
            translations: {
                de: "presse/{post}/",
                fr: "presse/{post}/",
                it: "stampa/{post}/",
                pt: "imprensa/{post}/",
                ru: "pressa/{post}/",
                tr: "basin/{post}/",
                cn: "新闻/{post}/",
                es: "prensa/{post}/",
            },
        } as UrlTranslation,
        tag: {
            original: "press/tag/{tag}/",
            translations: {
                de: "presse/tag/{tag}/",
                fr: "presse/tag/{tag}/",
                it: "stampa/tag/{tag}/",
                pt: "imprensa/tag/{tag}/",
                ru: "pressa/tag/{tag}/",
                tr: "basin/etiket/{tag}/",
                cn: "新闻/标签/{tag}/",
                es: "prensa/tag/{tag}/",
            },
        } as UrlTranslation,
    },
    faq: {
        guest: {
            original: "faq/",
            translations: {
                de: "haeufige-fragen/",
                pt: "perguntas-frequentes/",
                ru: "chasto-zadavaemye-voprosy/",
                tr: "sikca-sorulan-sorular/",
                cn: "常见问题/",
                es: "preguntas-frecuentes/",
            },
        } as UrlTranslation,
        hosts: {
            original: "faq/hosts/",
            translations: {
                de: "haeufige-fragen/gastgeber/",
                fr: "faq/hotes/",
                it: "faq/host/",
                pt: "perguntas-frequentes/anfitrioes/",
                ru: "chasto-zadavaemye-voprosy/khozyaev/",
                tr: "sikca-sorulan-sorular/ev-sahipleri/",
                cn: "常见问题/房东/",
                es: "preguntas-frecuentes/anfitriones/",
            },
        } as UrlTranslation,
    },
    becomeAHost: {
        index: {
            original: "become-a-host/",
            translations: {
                de: "gastgeber-werden/",
                fr: "devenir-hote/",
                it: "diventa-un-host/",
                pt: "torne-se-um-anfitriao/",
                ru: "stat-uchastnikom/",
                tr: "ev-sahibi-ol/",
                cn: "成为房东/",
                es: "conviertete-en-anfitrion/",
            },
        } as UrlTranslation,
    },
    aboutUs: {
        index: {
            original: "about-us/",
            translations: {
                de: "ueber-uns/",
                fr: "a-propos-de-nous/",
                it: "chi-siamo/",
                pt: "sobre-nos/",
                ru: "o-nas/",
                tr: "hakkimizda/",
                cn: "关于我们/",
                es: "sobre-nosotros/",
            },
        } as UrlTranslation,
    },
    contactUs: {
        index: {
            original: "contact-us/",
            translations: {
                de: "kontaktiere-uns/",
                fr: "nous-contacter/",
                it: "contattaci/",
                pt: "contate-nos/",
                ru: "svyazhites-s-nami/",
                tr: "bize-ulasin/",
                cn: "联系我们/",
                es: "contactanos/",
            },
        } as UrlTranslation,
    },
    imprint: {
        index: {
            original: "imprint/",
            translations: {
                de: "impressum/",
                fr: "impression/",
                it: "impronta/",
                pt: "impressao/",
                ru: "otpechatok/",
                tr: "imza/",
                cn: "版权声明/",
                es: "aviso-legal/",
            },
        } as UrlTranslation,
    },
    covid19: {
        index: "covid-19/",
    },
    securityInformation: {
        index: {
            original: "security-information/",
            translations: {
                de: "sicherheitsinformationen/",
                fr: "informations-de-securite/",
                it: "informazioni-di-sicurezza/",
                pt: "informacoes-de-seguranca/",
                ru: "informatsiya-o-bezopasnosti/",
                tr: "guvenlik-bilgileri/",
                cn: "安全信息/",
                es: "informacion-de-seguridad/",
            },
        } as UrlTranslation,
    },
    cookiePolicy: {
        index: {
            original: "cookie-policy/",
            translations: {
                de: "cookie-richtlinie/",
                fr: "politique-de-cookies/",
                it: "politica-dei-cookie/",
                pt: "politica-de-cookies/",
                ru: "politika-faylov-cookie/",
                tr: "cerez-politikasi/",
                cn: "cookie政策/",
                es: "politica-de-cookies/",
            },
        } as UrlTranslation,
    },
    cancellationPolicy: {
        index: {
            original: "cancellation-policy/",
            translations: {
                de: "stornierungsbedingungen/",
                fr: "politique-d-annulation/",
                it: "politica-di-cancellazione/",
                pt: "politica-de-cancelamento/",
                ru: "usloviya-otmeny/",
                tr: "iptal-sartlari/",
                cn: "取消政策/",
                es: "politica-de-cancelacion/",
            },
        } as UrlTranslation,
    },
    privacyPolicy: {
        index: {
            original: "privacy-policy/",
            translations: {
                de: "datenschutzerklaerung/",
                fr: "politique-de-confidentialite/",
                it: "informativa-sulla-privacy/",
                pt: "politica-de-privacidade/",
                ru: "politika-konfidencialnosti/",
                tr: "gizlilik-politikasi/",
                cn: "隐私政策/",
                es: "politica-de-privacidad/",
            },
        } as UrlTranslation,
    },
    termsAndConditionsHosts: {
        index: {
            original: "terms-and-conditions-hosts/",
            translations: {
                de: "allgemeine-geschaeftsbedingungen-gastgeber/",
                fr: "conditions-generales-d-utilisation-des-hotes/",
                it: "termini-e-condizioni-per-host/",
                pt: "termos-e-condicoes-anfitrioes/",
                ru: "usloviya-i-polozheniya-dlya-khozyaev/",
                tr: "ev-sahibi-sartlari/",
                cn: "房东条款/",
                es: "terminos-y-condiciones-para-anfitriones/",
            },
        } as UrlTranslation,
    },
    termsAndConditionsGuests: {
        index: {
            original: "terms-and-conditions-guests/",
            translations: {
                de: "allgemeine-geschaeftsbedingungen-gaeste/",
                fr: "conditions-generales-d-utilisation-des-voyageurs/",
                it: "termini-e-condizioni-per-ospiti/",
                pt: "termos-e-condicoes-hospedes/",
                ru: "usloviya-i-polozheniya-dlya-gostei/",
                tr: "misafir-sartlari/",
                cn: "客人条款/",
                es: "terminos-y-condiciones-para-huespedes/",
            },
        } as UrlTranslation,
    },
    register: {
        index: {
            original: "registration/",
            translations: {
                de: "registrierung/",
                fr: "inscription/",
                it: "registrazione/",
                pt: "registro/",
                ru: "registratsiya/",
                tr: "kayit/",
                cn: "注册/",
                es: "registro/",
            },
        } as UrlTranslation,
    },
    emailVerification: {
        index: "email-verification/",
    },
    resetPassword: {
        index: "reset-password/",
    },
    checkout: {
        index: {
            original: "checkout/",
            translations: {
                de: "kasse/",
                fr: "paiement/",
                it: "pagamento/",
                pt: "finalizar/",
                ru: "oformlenie-zakaza/",
                tr: "odeme/",
                cn: "结帐/",
                es: "pago/",
            },
        } as UrlTranslation,
        paymentCheck: {
            index: {
                original: "checkout/payment-check/",
                translations: {
                    de: "kasse/zahlungsprüfung/",
                    fr: "paiement/verification-paiement/",
                    it: "pagamento/verifica-pagamento/",
                    pt: "finalizar/verificacao-pagamento/",
                    ru: "oformlenie-zakaza/proverka-oplaty/",
                    tr: "odeme/odeme-kontrolu/",
                    cn: "结帐/支付检查/",
                    es: "pago/comprobacion-pago/",
                },
            } as UrlTranslation,
        },
    },
    property: {
        index: {
            original: "property",
            translations: {
                de: "immobilie",
                fr: "propriété",
                it: "proprietà",
                pt: "propriedade",
                ru: "собственность",
                tr: "mülk",
                cn: "财产",
                es: "propiedad",
            },
        } as UrlTranslation,
    },
    // External URL's
    inquiry: {
        index: "https://business.myroom24.com/anfrage",
    },
    hostPanel: {
        index: "https://panel.myroom24.com/auth/login",
    },
};

export function getUrl(
    path: string | UrlTranslation,
    lang: string,
    props?: Record<string, string> | undefined | null,
    query?: string | undefined,
) {
    if (typeof path !== "string") {
        if (Object.keys(path.translations).includes(lang) && process.env.NODE_ENV !== "development") {
            path = path.translations[lang];
        } else {
            path = path.original;
        }
    }

    if (props) {
        for (const prop in props) {
            path = path.replaceAll(`{${prop}}`, props[prop]);
        }
    }
    if (lang) {
        let addLang = true;
        // check if lang is in "custom" sites - sites with custom domain
        if (Object.keys(SITES.custom).includes(lang) && process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true") {
            addLang = false;
        }
        if (addLang) {
            path = `/${lang}/${path}`;
        } else if (!path.startsWith("/")) {
            path = `/${path}`;
        }
    }
    if (query) {
        path = `${path}?${query}`;
    }

    return path.replaceAll("//", "/"); // just in case anyone formats Urls in a wrong way.
}

function cleanUp(url: string) {
    // safari < 16.4 hack:
    const urlSplit = url.split("://");
    if (urlSplit.length === 1) {
        return urlSplit[0].replace(/\/\//gm, "/");
    }
    return `${urlSplit[0]}://${urlSplit[1].replace(/\/\//gm, "/")}`;
}

export function getCanonicalUrl(
    path: string | UrlTranslation,
    lang: string,
    props?: Record<string, string> | undefined | null,
    query?: string | undefined,
) {
    const { domain } = getDomain(lang);
    const url = getUrl(path, lang, props, query);
    return cleanUp(`${domain}${url}`);
}
